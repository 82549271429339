<template>
    <section class="section section-lg brands-inpi">
        <div class="container">
            <div class="row row-grid align-items-center">
                <div class="col-md-6 order-md-2">
                    <img src="img/theme/contract.png" class="img-fluid floating rounded mx-auto d-block ">
                </div>
                <div class="col-md-6 order-md-1">
                    <div class="pr-md-5">
                        <icon name="fa fa-certificate" class="mb-5" size="lg" type="dark" shadow
                              rounded></icon>
                        <h3>Registre sua marca</h3>
                        <p class="text-justify">
                            Registrar a sua marca e passo mais importante para o sucesso do seu negócio.
                            O registro da <em><strong>MARCA</strong></em> demonstra confiança, responsabilidade e 
                            exclusividade. Não perca mais tempo inicie o registro hoje mesmo.</p>
                        <ul class="list-unstyled mt-5">
                            <li class="py-2">
                                <div class="d-flex align-items-center">
                                    <badge type="dark" circle class="mr-3" icon="fa fa-copyright"></badge>
                                    <h6 class="mb-0">Evita copia ou uso indevido.</h6>
                                </div>
                            </li>
                            <li class="py-2">
                                <div class="d-flex align-items-center">
                                    <badge type="dark" circle class="mr-3" icon="fa fa-handshake-o"></badge>
                                    <h6 class="mb-0">Passa credibilidade.</h6>
                                </div>
                            </li>
                            <li class="py-2">
                                <div class="d-flex align-items-center">
                                    <badge type="dark" circle class="mr-3" icon="fa fa-line-chart"></badge>
                                    <h6 class="mb-0">Possibilidade de franquear.</h6>
                                </div>
                            </li>
                        </ul>
                        <div class="btn-wrapper">
                            <base-button tag="a"
                                        href="/brands"
                                        class="mb-3 mb-sm-0"
                                        type="dark">
                                Registrar Minha Marca
                            </base-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.brands-inpi {

}
</style>
