<template>
  <section class="section-hero section-shaped my-0">
    <div class="shape shape-style-1 shape-primary">
      <span class="span-150"></span>
      <span class="span-50"></span>
      <span class="span-50"></span>
      <span class="span-75"></span>
      <span class="span-100"></span>
      <span class="span-75"></span>
      <span class="span-50"></span>
      <span class="span-100"></span>
      <span class="span-50"></span>
      <span class="span-100"></span>
    </div>
    <div class="container shape-container d-flex align-items-center">
      <div class="col px-0">
        <div class="container py-0 pb-lg">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-5 mb-5 mb-lg-0">
              <h1 class="text-white font-weight-light">Bairon Tecnologia</h1>
              <p class="text-white lead mt-4">
                Soluções inteligentes para negócios inovadores. Especialista em
                statups e meios de pagamento.
              </p>
              <a
                href="#formContatUs"
                class="btn btn-white mt-4">CONTATO</a>
            </div>
            <div class="col-lg-6 mb-lg-auto">
              <div
                class="rounded shadow-lg overflow-hidden transform-perspective-right">
                <b-carousel id="carousel1" controls indicators>
                  <b-carousel-slide
                  v-for="item in carrousels"
                    :key="item.id"
                    :img-src="item.img">
                    <div>
                    <h3>{{ item.title }}</h3>
                    <span>{{ item.description }}</span>
                    </div>
                  </b-carousel-slide>
                </b-carousel>
              </div>
            </div>
          </div>
        </div>

        <!-- SVG separator -->
        <div
          class="row align-items-center justify-content-around stars-and-coded">
          <div class="col-sm-4">
            <span class="text-white alpha-7">Nos siga nas redes sociais</span>
            <div class="btn-wrapper">
              <a
                target="_blank"
                rel="noopener"
                href="https://api.whatsapp.com/send?phone=5511934450710&text=Ola, gostaria de saber sobre seus serviços."
                class="btn btn-neutral btn-icon-only btn-whatsapp btn-round btn-lg"
                data-toggle="tooltip"
                data-original-title="Star on Github">
                <i class="fa fa-whatsapp"></i>
              </a>
              <a
                target="_blank"
                rel="noopener"
                href="https://www.linkedin.com/company/bairon"
                class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg"
                data-toggle="tooltip"
                data-original-title="Follow us">
                <i class="fa fa-linkedin"></i>
              </a>
              <a
                target="_blank"
                rel="noopener"
                href="https://www.facebook.com/baironbr"
                class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg"
                data-toggle="tooltip"
                data-original-title="Like us">
                <i class="fa fa-facebook-square"></i>
              </a>
              <a
                target="_blank"
                rel="noopener"
                href="https://github.com/BaironBR"
                class="btn btn-neutral btn-icon-only btn-github btn-round btn-lg"
                data-toggle="tooltip"
                data-original-title="Star on Github">
                <i class="fa fa-github"></i>
              </a>
            </div>
          </div>
          <div class="col-sm-4 mt-4 mt-sm-0 text-right">
            <span class="text-white alpha-7">"Trabalho duro constrói coisas sublimes."</span>
            <a
            class="text-white"
              href="https://www.linkedin.com/in/dominic-monteiro"
              target="_blank"
              title="Dominic LinkedIn">
              - Dominic Monteiro, founder
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BCarousel } from "bootstrap-vue";
import { BCarouselSlide } from "bootstrap-vue";

export default {
  components: {
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      carrousels: [
        {
          id: 2,
          img: "/image/landing/carroussel/a-i.jpg",
          title: "Inteligência Artificial",
          description: 'Usamos inteligência artificial para resolver problemas e otimizar processos.'
        },
        {
          id: 3,
          img: "/image/landing/carroussel/business.jpg",
          title: "Soluções para Negócio",
          description: 'Soluções para gestão empresarial.'
        },
        {
          id: 4,
          img: "/image/landing/carroussel/ti.jpg",
          title: "Soluções de TI",
          description: 'Criação de sistemas, sites e aplicativos.'
        },
        {
          id: 5,
          img: "/image/landing/carroussel/bank.jpg",
          title: "Criação de bancos digitais",
          description: 'Criamos bancos digitais para empresas. Economize com custos de infraestrutura e equipe.'
        },
        {
          id: 6,
          img: "/image/landing/carroussel/business.jpg",
          title: "Machine Learning",
          description: 'Seu sistema aprendendo conforme o uso.'
        },
        {
          id: 7,
          img: "/image/landing/carroussel/wallet.jpg",
          title: "Criação de wallets",
          description: 'Carteiras digitais para armazenamento de moedas fiduciárias e criptomoedas.'
        },
        {
          id: 1,
          img: "/image/landing/carroussel/chatbot.jpg",
          title: "ChatBot",
          description: 'Criamos ChatBot para atendimento ao público. Chega de filas e espera!'
        },
        {
          id: 8,
          img: "/image/landing/carroussel/payment.jpg",
          title: "Integrações financeiras para pagamento",
          description: 'Integrações financeiras para pagamento de boletos, Pix, cartões de crédito e débito.'
        }
      ],
    };
  }
};
</script>
<style lang="scss">
.section-shaped .shape-style-1.shape-primary {
  background: linear-gradient(150deg, #6772e5 15%, #6e8cd5 70%, #6772e5 94%);
}
.btn-whatsapp {
  background-color: #25d366;
  border-color: #18cd5a;
  color: #fff;
}
#carousel1 {
  .carousel-caption {
    background-color: #cdcdcdd1;
    width: 100%;
    min-height: 70px;
    left: 0;
    right: 0;
    h3 {
      border-radius: 6px;
      color: #000000b5;
    }
    span {
      border-radius: 6px;
      color: rgba(0, 0, 0, 0.772);
      font-size: 0.9em;
    }
  }
  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
}

a.carousel-control-next > span.carousel-control-next-icon {
  background-color: #00000082;
  box-shadow: 0px 0px 21px 4px #000;
}
a.carousel-control-prev > span.carousel-control-prev-icon {
  background-color: #00000082;
  box-shadow: 0px 0px 21px 4px #000;
}
.carousel-indicators {
  bottom: -5px;
}
.carousel-caption {
  bottom: 0;
}
</style>
