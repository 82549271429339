<template>
  <section class="section section-lg section-nucleo-icons pb-0">
    <div class="container">
      <div
        class="row row-grid align-items-center"
        @mouseleave="showIconsAnimation = false">
        <div class="col-md-6 order-md-1" @mouseover="showIconsAnimation = true">
          <div class="text-center">
            <h2 class="display-3">Soluções tecnológicas sob medida</h2>
            <p class="lead text-justify">
              Não espere que aquela sua ideia caia do céu pronta! Nós fazemos
              acontecer para você. Receba todo o suporte necessário para que sua
              ideia se torne realidade. Time multidisciplinar e com experiência
              em projetos de diferentes portes e segmentos.
            </p>
            <div class="btn-wrapper">
              <a
                href="/consultoria-e-tecnologia"
                class="btn btn-default mt-3 mt-md-0">SABER MAIS</a>
            </div>
          </div>
        </div>
        <div class="col-md-6 order-md-2 blur--hover overflow-hidden">
          <div>
            <div
              class="icons-container blur-item mt-5"
              :class="{ 'on-screen': showIconsAnimation }">
              <!-- Center -->
              <i><img
                  src="/image/landing/logotipo.png"
                  alt="Bank Maker"
                  class="logo-tech"/></i>
              <!-- Right 1 -->
              <i class="icon icon-sm ni ni-album-2"></i>
              <i class="icon icon-sm ni ni-app"></i>
              <i class="icon icon-sm ni ni-atom"></i>
              <!-- Right 2 -->
              <i class="icon ni ni-bag-17"></i>
              <i class="icon ni ni-bell-55"></i>
              <i class="icon ni ni-credit-card"></i>
              <!-- Left 1 -->
              <i class="icon icon-sm ni ni-briefcase-24"></i>
              <i class="icon icon-sm ni ni-building"></i>
              <i class="icon icon-sm ni ni-button-play"></i>
              <!-- Left 2 -->
              <i class="icon ni ni-calendar-grid-58"></i>
              <i class="icon ni ni-camera-compact"></i>
              <i class="icon ni ni-chart-bar-32"></i>
            </div>
            <span class="blur-hidden h5 text-success">Vamos fazer acontecer!</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      showIconsAnimation: false,
    };
  },
};
</script>
<style scoped lang="scss">
.logo-tech {
  width: 50px;
  margin: 4px 8px 11px 8px;
}
.overflow-hidden {
  overflow: hidden;
}
</style>
