<template>
  <section class="section section-lg">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-2">
          <img src="/image/landing/fintech.jpg" class="img-fluid floating" />
        </div>
        <div class="col-md-6 order-md-1">
          <div class="pr-md-5">
            <h2 class="display-3">Fintechs e Bancos Digitais</h2>
            <p class="lead text-justify">
              Já pensou em ter um sistema de gestão financeira para sua fintech ou banco digital?<br>
              Ou mesmo ter um banco digital para sua empresa?<br>
              Seu banco ou fintech pode ficar pronto em 1 mês. Nossas soluções White Label se encaixam perfeitamente no seu negócio.<br> 
            </p>
            <ul class="list-unstyled mt-5">
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <badge
                    type="success"
                    circle
                    class="mr-3"
                    icon="ni ni-settings-gear-65"></badge>
                  <h6 class="mb-0">Personalizável</h6>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <badge
                    type="success"
                    circle
                    class="mr-3"
                    icon="ni ni-html5"></badge>
                  <h6 class="mb-0">Emita cartões bandeirados</h6>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <badge
                    type="success"
                    circle
                    class="mr-3"
                    icon="ni ni-satisfied"></badge>
                  <h6 class="mb-0">Site e Aplicativo</h6>
                </div>
              </li>
            </ul>
            <div class="btn-wrapper text-center">
              <a
              href="/solucoes-bancarias-e-meio-de-pagamento"
                class="btn btn-default mt-3 mt-md-0">SABER MAIS</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
