<template>
  <div>
    <carroussel-header />
    <tech-consulting />
    <fintech />
    <payment-integration />
    <!--<message-contact-us />-->
    <section class="section section-lg section-shaped overflow-hidden my-0">
      <div class="shape shape-style-3 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <contact />
    </section>
  </div>
</template>

<script>
//import { MessageContactUs } from "@/components/Banking";

import CarrousselHeader from "@/components/Landing/CarrousselHeader.vue";
import TechConsulting from "@/components/Landing/TechConsulting.vue"
import Fintech from "@/components/Landing/Fintech.vue";

import PaymentIntegration from "../components/Landing/PaymentIntegration.vue";

import { Contact } from "@/components";

export default {
  name: "LandingBody",
  components: {
//    MessageContactUs,
    Contact,
    CarrousselHeader,
    TechConsulting,
    Fintech,
    PaymentIntegration
  }
};
</script>
