<template>
  <header class="header-global">
    <base-nav class="navbar-main" transparent type="" effect="dark" expand>
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img src="/image/logo_bairon.png" alt="logo" />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a
            href="/">
            <img src="/image/logo_bairon.png" alt="logo" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl" v-for="itemMenu in menu" :key="itemMenu.title">
          <a
            slot="title"
            :href="itemMenu.url ? itemMenu.url : '#'"
            class="nav-link"
            data-toggle="dropdown"
            role="button">
            <!--<i :class="itemMenu.icon"></i>-->
            <span class="nav-link-inner--text">{{ itemMenu.title }}</span>
          </a>
          <div class="dropdown-menu-inner" v-if="itemMenu.items.length > 0">
            <router-link v-for="item in itemMenu.items" :key="item.title" :to="item.url" class="dropdown-item">{{ item.title }}</router-link>
          </div>
        </base-dropdown>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import { BaseNav, BaseDropdown, CloseButton } from "@/components";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },
  data() {
    return  {
      menu: [
        {
          title: "Fintech",
          icon: "fa-solid fa-building-columns",
          items: [
            {
              title: "Criar Canco Digital",
              url: "/solucoes-bancarias-e-meio-de-pagamento",
            },
            {
              title: "Carteira Digital",
              url: "/solucoes-bancarias-e-meio-de-pagamento",
            },
            {
              title: "Emissão de Cartões",
              url: "/solucoes-bancarias-e-meio-de-pagamento",
            },
            {
              title: "Meios de Pagamentos",
              url: "/solucoes-bancarias-e-meio-de-pagamento",
            }
          ]
        },
        {
          title: "Tecnologia",
          icon: "fa-regular fa-laptop-mobile",
          items: [
            {
              title: "Desenvolvimento de Software customizado",
              url: "/consultoria-e-tecnologia"
            },
            {
              title: "Criação de sites & Apps",
              url: "/consultoria-e-tecnologia"
            },
            {
              title: "Pentest & segurança da informação",
              url: "/consultoria-e-tecnologia"
            },
            {
              title: "Criação CRM & ERP",
              url: "/consultoria-e-tecnologia"
            }
          ]
        }
      ]
    }
  }
};
</script>
<style lang="scss">
.dropdown-item.active, .dropdown-item:active {
  background-color: unset;
  color: #000000ab;
}

.navbar-brand img {
    height: 50px;
}
.navbar-main {
  background-color: #3c3c3ccc;
}
</style>
