<template>
  <section class="section section-lg">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-2">
          <img src="image/landing/payment2.jpg" class="img-fluid floating" />
        </div>
        <div class="col-md-6 order-md-1">
          <div class="pr-md-5">
            <h2 class="display-3">Meios de Pagamentos</h2>
            <p class="lead text-justify">
              Receba pagamentos através de Pix, boleto, TED ou links de pagamento.<br>
              As soluções de pagamento podem integrar-se através de APIs a qualquer sistema de gestão de vendas, como ERP, CRM, e-commerce, etc. Ou, pode-se utilizar a interface de pagamento produzida pelo Bairon.
            </p>
            <ul class="list-unstyled mt-5">
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <badge
                    type="default"
                    circle
                    class="mr-3"
                    icon="ni ni-settings-gear-65"></badge>
                  <h6 class="mb-0">Módulo de pagamento</h6>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <badge
                    type="default"
                    circle
                    class="mr-3"
                    icon="ni ni-html5"></badge>
                  <h6 class="mb-0">APIs</h6>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <badge
                    type="default"
                    circle
                    class="mr-3"
                    icon="ni ni-satisfied"></badge>
                  <h6 class="mb-0">Links de pagamentos</h6>
                </div>
              </li>
            </ul>
            <div class="btn-wrapper text-center">
              <a
                href="/solucoes-bancarias-e-meio-de-pagamento"
                class="btn btn-default mt-3 mt-md-0">SABER MAIS</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
