<template>
  <section class="section section-shaped section-lg my-0 login-page">
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div>
      <brands-inpi/>
    </div>
  </section>
</template>

<script>
import BrandsInpi from "@/components/Brand/BrandsInpi.vue";

export default {
  name: "BrandsInpi",
  components: {
    BrandsInpi
  },
};
</script>
