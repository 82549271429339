<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-6"
            body-classes="px-lg-5 py-lg-6"
            class="border-0"
          >
            <template>
              <div class="text-muted text-center mb-3">
                <h3>Page Not Found</h3>
              </div>
              <div class="text-center">
                <p>Não foi possível encontrar a página.</p>
              </div>
            </template>
            <template>
              <div class="text-center text-muted">
                <small
                  >Clique no botão abaixo para voltar a página inicial</small
                >
              </div>
              <div class="text-center">
                <router-link :to="{ name: 'landing' }" exact
                  ><base-button type="primary" class="my-4"
                    >Início</base-button
                  ></router-link
                >
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style>
</style>
