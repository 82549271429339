<template>
  <footer class="footer has-cards">
    <div class="container">
      <hr />
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-4">
          <div>
            <img src="/image/logo_bairon.png" class="img-logo" />
          </div>
          <div>
            <p style="color: #d9d9d9!important;">Transformando ideias em soluções.</p>
          </div>
        </div>
        <div class="col-md-4">
          <ul class="nav nav-footer">
            <li class="nav-item">
              <a
                href="/solucoes-bancarias-e-meio-de-pagamento"
                class="nav-link">Meios de Pagamentos</a>
            </li>
            <li class="nav-item">
              <a
                href="/solucoes-bancarias-e-meio-de-pagamento"
                class="nav-link">Assessoria Abertura de Banco</a>
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul class="nav nav-footer">
            <li class="nav-item">
              <a
                href="/consultoria-e-tecnologia"
                class="nav-link">Criação de sites & apps</a>
            </li>
            <li class="nav-item">
              <a
                href="/consultoria-e-tecnologia"
                class="nav-link">Consultoria Tecnologia</a>
            </li>
          </ul>
        </div>
        <div class="copyright text-center col-12">
          Todos os direitos reservados &copy; {{ year }}
          <a href="https://bairon.com.br">Bairon</a>.
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style scoped lang="scss">
footer,
.footer {
  background-color: #18191b;
  padding-top: unset;
  margin-top: unset;
  .nav-footer {
    display: inline-block;
    .nav-link {
      color: #d0d0d0!important;
    }
  }
  .img-logo {
    width: 100px;
  }

  @media screen and (max-width: 768px) {
    div > div {
      text-align: center;
    }
  }
}
</style>
